import React  from "react";
import {  Layout } from "antd";


const { Header, Content, Footer } = Layout;


const PublicLayout  = ({children}: {children: any}) => {

    console.log("LoginLayout random " + Math.random());


    return (

        <Layout style={{ minHeight: '100vh' }}>

            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    Public Page: anyone can view it
                </Header>

                <Content style={{ margin: '0 16px' }}>

                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                        {children}

                    </div>
                </Content>

                <Footer style={{ textAlign: 'center' }}>© 2023 Developed by <a href={'https://www.hurasoft.vn'} target={'_blank'} rel="noreferrer">HuraSoft</a></Footer>

            </Layout>

        </Layout>
    )
};

export default PublicLayout;
