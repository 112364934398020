import { Avatar, Menu, Spin } from 'antd';
import React from 'react';

import HeaderDropdown from '../HeaderDropdown';

import FormattedMessage from "@/components/FormattedMessage";
import {useAuth} from "@/components/Auth/login";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "@/store/typing";

import styles from './index.less';

const AvatarDropdown = () => {

    const auth      = useAuth();
    const history   = useHistory();

    const adminInfo = useSelector((state: AppState) => state.user_info);

    // console.log('AvatarDropdown/adminInfo', adminInfo);

    const onMenuClick = (event: any) => {
        const { key } = event;

        if (key === 'logout') {
            auth.signOut(() => {
                history.replace("/login");
            });
           return;
        }

        if (key === 'info') {
            history.push("/account/info");
            return;
        }

        history.push("/account");
    }

  const menuHeaderDropdown = (
      <Menu selectedKeys={[]} onClick={onMenuClick}>
            <Menu.Item key="home">
              {/*<Icon type="user" />*/}
              <FormattedMessage id="menu.account.center" defaultMessage="account center" />
            </Menu.Item>

            {/*<Menu.Item key="info">
              <Icon type="setting" />
              <FormattedMessage id="menu.account.settings" defaultMessage="account settings" />
            </Menu.Item>*/}

            <Menu.Divider />

            <Menu.Item key="logout">
              {/*<Icon type="logout" />*/}
              <FormattedMessage id="menu.account.logout" defaultMessage="logout" />
            </Menu.Item>
      </Menu>
  )


  return adminInfo ? (
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span >
          <Avatar size="small" src={adminInfo.avatar} alt="avatar" />
          <span >{adminInfo.name}</span>
        </span>
      </HeaderDropdown>
  ) : (
      <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
  );
}


export default AvatarDropdown;
