import {formatMessage} from "@/lib/locale";

export interface MessageDescriptor {
    id: string;
    defaultMessage?: string;
}


const FormattedMessage = ({id, defaultMessage = ''} : MessageDescriptor) => {
    const txt = formatMessage({id, defaultText: defaultMessage});

    return (
        <>{txt}</>
    )
}

export default FormattedMessage;