import React, {useState} from "react";

import {RegisterForm} from "./components/RegisterForm";
import Confirm from "./components/Confirm";
import {useLocation} from "react-router-dom";

// import "./styles.css";


const Register = () => {

    const location  = useLocation();

    const current_path = location.pathname;


    if(current_path == '/register/confirm') {
        return <Confirm />
    }


    return (
        <RegisterForm />
    )
}


export default Register;
