import React, {useState} from "react";

import {NoPasswordForm} from "./NoPasswordForm";
import {PasswordForm} from "./PasswordForm";

// import "./styles.css";


const LoginForm = () => {

    const [openForgetPassword, setOpenForgetPassword]   = useState<boolean>(false);

    if(openForgetPassword) {
        return (
            <NoPasswordForm setOpenForgetPassword={setOpenForgetPassword}  />
        )
    }

    return (
        <PasswordForm setOpenForgetPassword={setOpenForgetPassword} />
    )
}


export default LoginForm;
