
export enum ErrorCode {
    OK,
    ERROR,
    UNAUTHORIZED
}

export interface APIResponse {
    errCode: ErrorCode,
    msg: string,
    data: any
}
