export const IS_DEV = false;
//export const IS_DEV = true;

// set to false when developing
export const HIDE_UNFINISHED = !IS_DEV;

export const REDIRECTOR_URL  = '/redirect.php';

// api proxied to http://local.chatngay.api/admin/
// example: /api/client/settings -> proxied to: http://local.chatngay.api/admin/client/settings
// in development, the setting in file ./src/setupProxy.js
// in production, set up the nginx proxy
// real api server:
// dev: http://local.chatngay.api/admin/
// pro: https://api195.chatngay.com/admin/
export const SERVER_API          = '/api/';

export const UPLOAD_ENDPOINT  = '/upload';

export const UPLOAD_MEDIA_ENDPOINT = UPLOAD_ENDPOINT + '/handle.php';
//export const UPLOAD_EXCEL_ENDPOINT = SERVER_API + '/excel_upload.php'; //form/main/excel/upload

export const DEFAULT_AVATAR      = 'https://randomuser.me/api/portraits/men/40.jpg';

export const WEBWORKER_URL = '/static/webworker_client.js'; // in /public/static/webworker_client.js

export const FALLBACK_ERROR_IMAGE = 'https://www.chatngay.com/static/images/logo.png';
export const FILE_CDN_DOMAIN = 'http://aman.gleecdn.com/';

export const TINYMCE_API_KEY = 'wi95smrelsfeu323ggkhhroce0y5knikcydus4e28addsj2j';

// key to store access-code / user-id / client-id
export const CREDENTIAL_SAVE_KEY = 'aman_cr';

// key to store app's state
export const STATE_STORE_KEY = 'aman_st';

export const DEFAULT_PAGE_SIZE = 20;
