import React, {useState} from "react";
import api, {APIResultType} from "@/lib/api";
import {Button, Form, Input} from "antd";
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import {useAuth, UserCredentialType} from "@/components/Auth/login";
import {useHistory} from "react-router-dom";


export const NoPasswordForm = ({setOpenForgetPassword} : {setOpenForgetPassword: (v: boolean) => void}) => {

    const auth      = useAuth();
    const history   = useHistory();

    const [submitted, setSubmitted]   = useState<boolean>(false);
    const [user_email, setEmail]   = useState<string>('');
    const [loginStatus, setLoginStatus]   = useState<number>(0);

    const onFinish = async (values: any) => {
        //console.log('Received values of form: ', values);
        setSubmitted(true);
        setEmail(values.u ?? '');
        const result = await api.post('login/send-otp', values);
        console.log(result);
    }

    const onVerifyOTP = async (values: any) => {
        //console.log('Received values of form: ', values);
        setLoginStatus(1);
        const result: APIResultType<UserCredentialType> = await api.post('login/verify-otp', {...values, u: user_email});
        //console.log(result);

        if(result.status === 'error') {
            setLoginStatus(-1);
        }else{
            await auth.signIn(result.data);
            // back to previous page
            history.replace('/');
        }

    }

    if(submitted) {
        return (
            <>
                <h3>Vui lòng kiểm tra email và nhập mã OTP vào ô dưới đây.</h3>

                <div style={{width: 300}}>

                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onVerifyOTP}
                        autoComplete={'off'}
                    >
                        {
                            (loginStatus === -1) && (
                                <Form.Item>
                                    <div style={{color: 'red'}}>
                                        Thông tin đăng nhập sai.
                                    </div>
                                </Form.Item>
                            )
                        }

                        <Form.Item
                            name="otp"
                            rules={[
                                { required: true, message: 'Chưa nhập mã' }
                            ]}
                        >
                            <Input autoComplete='off' prefix={<LockOutlined className="site-form-item-icon" />} placeholder="OTP" />
                        </Form.Item>

                        <Form.Item>
                            <Button loading={loginStatus === 1} type="primary" htmlType="submit" className="login-form-button">
                                Đăng nhập
                            </Button>
                        </Form.Item>
                    </Form>

                </div>

                <div>
                    Nếu bạn không nhận được email sau 2 phút, có thể bạn chưa có tài khoản tại adMan hoặc vui lòng liên hệ bộ phận hỗ trợ khách hàng của chúng tôi tại <a href={'https://adman.vn/support'} target={'_blank'}>https://adman.vn/support</a>.
                </div>

                <div style={{marginTop: 20}}>
                    <span className={'a-link'} onClick={() => setOpenForgetPassword(false)}>
                        Dùng form đăng nhập
                    </span>
                </div>
            </>
        );
    }


    return (
        <div style={{ width: 300 }}>
            <Form
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
            >
                <Form.Item>
                    <div>
                        Nhập email của bạn đã đăng ký để hệ thống gửi mã đăng nhập (OTP).
                    </div>
                </Form.Item>

                <Form.Item
                    name="u"
                    rules={[
                        { required: true, message: 'Chưa nhập email' },
                        { type: 'email', message: 'Email chưa đúng' }
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Gửi mã đăng nhập
                    </Button>
                </Form.Item>

            </Form>

            <div>
                <span className={'a-link'} onClick={() => setOpenForgetPassword(false)}>
                    Dùng form đăng nhập
                </span>
            </div>

        </div>
    );
};

