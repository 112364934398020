import React from "react";

export default function Error({message}: {message?: string}) {
    return (
        <>
            <div>
                Lỗi: {message || 'unknown'}
            </div>
        </>
    )
}
