import {createWebWorker} from "@/lib/webworker";
import store from "@/store";
import {actions} from "@/store/actions";
import {getCurrentUTCTimestamp} from "@/lib/utils";
import { WEBWORKER_URL, SERVER_API} from "@/config";
import {APIResponse} from "@/typings/network";
import api from "@/lib/api";
import {setMyPermission} from "@/components/Auth";

const webworker = createWebWorker(WEBWORKER_URL);


export async function getSettings() : Promise<any> {
    let client_query = await api.get("client/settings");
    return (client_query.status === 'ok') ? client_query.data : null;
}


export const setUp = async () : Promise<boolean> => {
    //updateRealStat(15); // use web-worker
    //await getSettings();
    await setMyPermission();

    return true;
}


// webworker to update user online and new notification
function updateRealStat(check_interval: number = 5) {
    const client_id = '';
    const stat_url = [SERVER_API, 'monitor/stats', '?client=', client_id].join("");
    const callback = (res: APIResponse) => {
        if(res.errCode === 1) return;

        let data = res.data;

        store.dispatch(actions.updateStat(JSON.stringify({
            user_online: data.user_online,
            admin_online: data.admin_online,
        })));

        store.dispatch({
            type: "UPDATE_USER_LIST_STATUS",
            payload: data.checkUser,
        })
    }

    _sendTask();
    setTimeout(updateRealStat, check_interval * 1000);

    function _sendTask() {
        webworker.sendTask({
            type: 'fetch',
            task_id: 'stat-' + getCurrentUTCTimestamp(),
            url: stat_url
        }, callback);
    }
}
