import {PrivateRouteType} from "@/typings/route";
import React from "react";

// child routes
import {report_child_routes} from "@/routes/pages/report";
import {setup_child_routes} from "@/routes/pages/setup";
import {tool_child_routes} from "@/routes/pages/tool";


export const private_routes: PrivateRouteType[] = [
    {
        id: 'dashboard',
        permission_id: 'dashboard',
        path: '/',
        exact: true,
        component: React.lazy(() => import('@/pages/Dashboard'))
    },
    {
        id: 'contact',
        permission_id: 'contact',
        path: '/contact',
        component: React.lazy(() => import('@/pages/contact'))
    },
    {
        id: 'support',
        permission_id: 'support',
        path: '/support',
        component: React.lazy(() => import('@/pages/Support'))
    },
    {
        id: 'files',
        permission_id: 'files',
        path: '/file',
        component: React.lazy(() => import('@/pages/Files'))
    },
    {
        id: 'account',
        permission_id: 'account',
        path: '/account',
        exact: true,
        component: React.lazy(() => import('@/pages/myaccount'))
    },
    {
        id: 'report',
        permission_id: 'report',
        path: '/report',
        child_routes: report_child_routes
    },
    {
        id: 'tools',
        permission_id: 'tools',
        path: '/tool',
        child_routes: tool_child_routes,
    },
    {
        id: 'setup',
        permission_id: 'setup',
        path: '/setup',
        child_routes: setup_child_routes,
    },
    {
        id: 'search',
        permission_id: 'search',
        path: '/search',
        exact: true,
        component: React.lazy(() => import('@/pages/search'))
    },
];


export const private_full_layout_routes : PrivateRouteType[] = [
    {
        id: 'tools.landing-page.code',
        permission_id: 'tools.landing-page',
        path: '/tool/landing-page/code',
        exact: true,
        component: React.lazy(() => import('@/pages/tools/landing_page/Code'))
    },
];
