import React, {Suspense} from 'react';
import { Drawer } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {actions} from "@/store/actions";
import {AppState} from "@/store/typing";
import Loading from "@/components/Loading";
import store from "@/store";
import {DrawerComponentType} from "./typings";

import {componentMap} from "./component_map";

export const openDrawer = (component: DrawerComponentType, args: any) => {
    store.dispatch(actions.openGlobalDrawer({component, args}))
}


const GlobalDrawer: React.FC = () => {

    const dispatch = useDispatch();
    const closeDrawer = () => {
        dispatch(actions.openGlobalDrawer({component: ''}))
    }
    const global_drawer = useSelector((state: AppState) => state.global_drawer);
    const drawer_component = (global_drawer !== '') ? JSON.parse(global_drawer) : {component: '', args: {}};

    // @ts-ignore
    const getMap: {title: string, component: React.LazyExoticComponent<any>} | null = componentMap[drawer_component.component];

    const getLoadedComponent = () => {

        if(getMap) {
            let Component: React.LazyExoticComponent<any>|null = getMap.component;
            return <Component id={drawer_component.args.id} />;
        }

        return null;
    }

    const Title = () => {
        if(getMap) {
            return <>{getMap.title}</>;
        }

        return null;
    }


    return (
        <>
            <Drawer
                title={<Title />}
                width={600}
                placement="right"
                closable
                onClose={closeDrawer}
                open={drawer_component.component !== ''}
            >
                <Suspense fallback={<Loading />}>
                    { getLoadedComponent() }
                </Suspense>
            </Drawer>
        </>
    );
};

export default GlobalDrawer;
