import React from 'react';
import ReactDOM from 'react-dom';
import { Provider} from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { history } from "./store";
import {runTest} from "./test";

// local
import {setUp} from "@/setup";
import Error from '@/components/Error';
import {getCurrentUTCTimestamp, isBrowserSupport} from "@/lib/utils";
import {getUserCredential} from "@/components/Auth/login";

// export React when we need to use external component from window
// window.React = React;


(async () => {
    const render_root = document.getElementById('root');

    if ( ! isBrowserSupport() ) {
        ReactDOM.render(<Error message={'Please use a modern browser in order to use this application'}/>, render_root);
        return;
    }


    if(_isPageRequiredLogin()) {
        // perhaps credential has been expired!
        if(_shouldRelogin(getUserCredential())  ) {
            _forceLogin();
            return;
        }

        await setUp();
    }


    const Root = () => (
        <Provider store={store}>
            <App history={history} />
        </Provider>
    )

    ReactDOM.render(<Root />, render_root);

    runTest();
})();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


function _shouldRelogin(user_check?: {t: number}) : boolean {
    // force relogin after 3 days
    if(!user_check) {
        return true;
    }

    if(user_check.t < getCurrentUTCTimestamp() - 3 * 24 * 3600) {
        return true;
    }

    // default
    return false;
}

function _forceLogin() {
    window.location.href = '/login?msg=login-required';
}

function _isPageRequiredLogin() {
    const current_path = window.location.pathname;
    //console.log('current_path', current_path);

    // if anything login
    if(current_path.substring(0, 6) == '/login') {
        return false;
    }

    // anything register
    if(current_path.substring(0, 9) == '/register') {
        return false;
    }

    // default: all must login
    return true;
}
