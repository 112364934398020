import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import {Action,  AppState, NetworkingStatusType } from "@/store/typing";
import {UserInfo} from "@/typings/user";


const updateNetworkingStatus = (current_status: NetworkingStatusType = 'offline', action: Action) : NetworkingStatusType => {
    if(action.type === 'UPDATE_NETWORKING_STATUS') {
        return action.payload;
    }

    return current_status;
}


const updateUserInfo = (current: UserInfo = {id: '', name: ''}, action: Action) : UserInfo => {
    if(action.type === 'UPDATE_USER_INFO') {
        let current_info = (current) ? current : {}
        return {...current_info, ...action.payload};
    }

    return current;
}

// {[key: string]: number}
const openGlobalModal = (current_stats: string = '', action: Action) => {
    if(action.type === 'OPEN_GLOBAL_MODAL') {
        return action.payload;
    }

    return current_stats;
}

const openGlobalDrawer  = (current_stats: string = '', action: Action) => {
    if(action.type === 'OPEN_GLOBAL_DRAWER') {
        return action.payload;
    }

    return current_stats;
}

const triggerSideMenuCollapse =  (current_stats: boolean = false, action: Action) => {
    if(action.type === 'TRIGGER_SIDE_MENU_COLLAPSE') {
        return action.payload;
    }

    return current_stats;
}


// redux summary:
// rootReducer is the composition of all pure functions registered beforehand to a store
// any dispatched action by store will search in the rootReducer to find corresponding function to modify state
// reducers can be added dynamically: see https://redux.js.org/recipes/code-splitting

const combinedReducer = (history: History) => combineReducers<AppState>({
    network_connection: updateNetworkingStatus,
    user_info: updateUserInfo,
    global_modal: openGlobalModal,
    global_drawer: openGlobalDrawer,
    side_menu_collapse: triggerSideMenuCollapse,
    router: connectRouter(history)
});

export const defaultState: AppState = {
    network_connection: 'online',
    user_info: {id: ''},
    global_modal: '',
    global_drawer: '',
    router: {location: {pathname: '/', search: '', hash: '', query: {}, state: ""}, action: "PUSH"}
}


function crossSliceReducer(state: AppState = defaultState, action: Action) {
    switch (action.type) {
        default:
            return state
    }
}

// copy from: https://redux.js.org/recipes/structuring-reducers/beyond-combinereducers
export const rootReducer = (history: History) => (state: AppState = defaultState, action: Action) => {
    const intermediateState = combinedReducer(history)(state, action);
    return crossSliceReducer(intermediateState, action) ;
}