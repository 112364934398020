import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useAuth, UserCredentialType} from "@/components/Auth/login";
import api, {APIResultType} from "@/lib/api";
import {Button, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";


export const PasswordForm = ({setOpenForgetPassword} : {setOpenForgetPassword: (v: boolean) => void}) => {

    const [loginStatus, setLoginStatus]    = useState<number>(0);

    const history   = useHistory();
    //const location  = useLocation();

    // @ts-ignore
    //let { from }    = location.state || { from: { pathname: "/" } };
    const auth      = useAuth();

    const onFinish = async (values: any) => {
        setLoginStatus(1);
        //console.log('Received values of form: ', values);
        const result: APIResultType<UserCredentialType> = await api.post('login/login', values);

        if(result.status === 'error') {
            setLoginStatus(-1);
        }else{
            await auth.signIn(result.data);
            // back to previous page
            history.replace('/');
        }
    }


    return (
        <>
            <h2>Đăng nhập</h2>

            <div style={{ width: 300 }}>
                <Form
                    name="normal_login"
                    className="login-form"
                    //initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="u"
                        rules={[
                            { required: true, message: 'Chưa nhập email' },
                            { type: 'email', message: 'Email chưa đúng' }
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                        name="p"
                        rules={[{ required: true, message: 'Chưa nhập mật khẩu' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Mật khẩu"
                        />
                    </Form.Item>

                    {
                        (loginStatus === -1) && (
                            <Form.Item>
                                <div style={{color: 'red'}}>
                                    Thông tin đăng nhập sai.
                                </div>
                            </Form.Item>
                        )
                    }

                    <Form.Item>
                        <Button loading={loginStatus === 1} type="primary" htmlType="submit" className="login-form-button">
                            Đăng nhập
                        </Button>
                    </Form.Item>

                </Form>

                <div>
                    <span className={'a-link'} onClick={() => setOpenForgetPassword(true)}>
                        Đăng nhập bằng mã OTP
                    </span>
                </div>

                <div style={{marginTop: 10}}>
                    <Link to={"/register"}>Đăng ký tài khoản</Link>
                </div>
            </div>
        </>
    );
}

