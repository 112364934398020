import {Action, NetworkingStatusType} from "@/store/typing";
import {ActionType} from "./typing.d";
import {UserInfo, VisibilityState} from "@/typings/user";


const changeUserVisibilityState = (new_state: VisibilityState) : Action => {
    return _createAction('UPDATE_USER_VISIBILITY_STATE', new_state);
}

const changeNetworkingStatus = (new_status: NetworkingStatusType) : Action => {
    return _createAction('UPDATE_NETWORKING_STATUS', new_status);
}

const changeUserInfo = (update_info: Partial<UserInfo>) : Action => {
    return _createAction('UPDATE_USER_INFO', update_info);
}

const updateStat = (stat = '') : Action => {
    return _createAction('UPDATE_STATS', stat);
}

const openGlobalModal = (payload: {component: string, args?: {[key: string]: any} }) : Action => {
    return _createAction('OPEN_GLOBAL_MODAL', JSON.stringify(payload));
}

const openGlobalDrawer = (payload: {component: string, args?: {[key: string]: any} }) : Action => {
    return _createAction('OPEN_GLOBAL_DRAWER', JSON.stringify(payload));
}

const triggerSideMenuCollapse = (payload: boolean ) : Action => {
    return _createAction('TRIGGER_SIDE_MENU_COLLAPSE', payload);
}

export const actions = {
    changeUserVisibilityState,
    changeNetworkingStatus,
    updateStat,
    changeUserInfo,
    openGlobalModal,
    openGlobalDrawer,
    triggerSideMenuCollapse
}

function _createAction(type: ActionType, payload: any) : Action {
    return {
        type,
        payload,
    }
}
