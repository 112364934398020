import React, {Suspense} from 'react'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import { useAuth } from '@/components/Auth/login';
import Error from "@/components/Error";
import Loading from "@/components/Loading";

// layout
import {PublicLayout, LoginLayout, SecureLayout, SecureFullLayout} from "@/layout/";

// single page
import Login from "@/pages/Login";
import RegisterHome from "@/pages/register";
import PublicComponent from "@/pages/Public";

import {PrivateRouteType} from "@/typings/route";
import {private_full_layout_routes, private_routes} from "./config";
import {checkRouteViewPermit} from "@/components/Auth";
import UnAuthorized from "@/components/Auth/UnAuthorized";


export const NestedRoutes = ({child_routes} : {child_routes: PrivateRouteType[]}) => {

    return (
        <Switch>
            {
                child_routes
                    .map((route) => {
                        const view_permit = checkRouteViewPermit(route);
                        const authorized = (route.child_routes && route.child_routes.length > 0) || view_permit;
                        return {...route, authorized}
                    })
                    .map((route) => {

                        if(route.child_routes && route.child_routes.length > 0) {
                            return (
                                <Route key={route.id} exact={route.exact} path={route.path}>
                                    <NestedRoutes child_routes={route.child_routes} />
                                </Route>
                            )
                        }

                        let Component: React.LazyExoticComponent<any>|undefined = route.component;
                        return (
                            <Route key={route.id} exact={route.exact} path={route.path}>
                                <Suspense fallback={<Loading />}>
                                    {
                                        route.authorized ? Component && <Component/> : <UnAuthorized route={route} />
                                    }
                                </Suspense>
                            </Route>
                        )
                    })
            }

            <Route component={Error} />

        </Switch>
    );
};
//

// @ts-ignore
function PrivateRoute({ children, ...rest }) {
    const auth = useAuth();

    return (
        <Route
            {...rest}
            render={ ({ location }) =>
                auth.user.user_id !== '' ? (children) :  (<Redirect to={{ pathname: '/login?f=' + location.pathname, state: { from: location } }} />)
            }
        />
    )
}


export function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/public'>
                    <PublicLayout>
                        <PublicComponent />
                    </PublicLayout>
                </Route>
                <Route exact path='/login'>
                    <LoginLayout>
                        <Login />
                    </LoginLayout>
                </Route>
                <Route path='/register'>
                    <LoginLayout>
                        <RegisterHome />
                    </LoginLayout>
                </Route>

                <PrivateRoute>

                    <Switch>
                        {
                            private_full_layout_routes
                                .map((route) => {
                                    const view_permit = checkRouteViewPermit(route);
                                    const authorized = (route.child_routes && route.child_routes.length > 0) || view_permit;

                                    return {...route, authorized}
                                })
                                .map((route) => {

                                    if(route.child_routes && route.child_routes.length > 0) {
                                        return (
                                            <Route key={route.id} exact={route.exact} path={route.path}>
                                                <SecureFullLayout>
                                                    <NestedRoutes child_routes={route.child_routes} />
                                                </SecureFullLayout>
                                            </Route>
                                        )
                                    }

                                    let Component: React.LazyExoticComponent<any>|undefined = route.component;
                                    return (
                                        <Route key={route.id} exact={route.exact} path={route.path}>
                                            <SecureFullLayout>
                                                <Suspense fallback={<Loading />}>
                                                    {
                                                        route.authorized ? Component && <Component/> : <UnAuthorized route={route} />
                                                    }
                                                </Suspense>
                                            </SecureFullLayout>
                                        </Route>
                                    )
                                })
                        }

                        <Route>
                            <SecureLayout>
                                <NestedRoutes child_routes={private_routes} />
                            </SecureLayout>
                        </Route>

                    </Switch>

                </PrivateRoute>

            </Switch>
        </BrowserRouter>
    )
}
