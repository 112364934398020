let registered_callbacks: {[key: string] : any} = {};
let _web_worker: { sendTask: (payload: {type: string, task_id: string, [key:string]: any}, callback: Function) => void }|undefined = undefined;


export function createWebWorker(url: string) {
    // don't create more than 1 worker instance
    if(_web_worker) return _web_worker;

    let _worker = new Worker(url);
    _worker.onmessage = handleWorkerMessage;
    _worker.onerror = handleWorkerError;

    _web_worker = {
        // payload = {type: '', task_id: '', ...}
        // callback = function(worker_data){...}
        sendTask: function (payload: { type: string, task_id: string, [key:string]: any}, callback: Function) {
            if(!_web_worker) {
                // console.error("_web_worker is not ready!");
                return;
            }

            _worker.postMessage(payload);
            registered_callbacks[payload.task_id] = callback;
        }
    }

    return _web_worker;


    function handleWorkerMessage(event: MessageEvent & {data: {task_id: string, info: any}}) {
        const {task_id, info} = event.data;
        if(registered_callbacks.hasOwnProperty(task_id)){
            if(typeof registered_callbacks[task_id] == 'function') {
                registered_callbacks[task_id](info);
                // then delete
                delete registered_callbacks[task_id];
            }
        }
    }

    function handleWorkerError(error: any) {
        //console.log('createWebWorker/handleWorkerError:');
        //console.log(error);
    }
}
