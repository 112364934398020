import { createBrowserHistory } from 'history';
import { applyMiddleware, Store, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import {defaultState, rootReducer} from './reducers';

import persist from "./persist";
import {AppState} from "@/store/typing";

const HASH_KEYS: {[key in keyof AppState]: string} = {
    network_connection: 'nc',
    user_info: 'ui',
    global_modal: 'gm',
    global_drawer: 'gd',
    side_menu_collapse: 'smc',
    router: 'rt'
};

export const history = createBrowserHistory();


function configureStore() : Store {
    const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const storedState = persist.get() || null;

    return createStore(
        rootReducer(history),
        // @ts-ignore
        unobfucateState(storedState) || defaultState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                thunk
            ),
        ),
    )

    // Hot reloading
    /*if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }*/

    //return store;
}

const store = configureStore();

// store state for subsequent reloads, dont store: new_messages
store.subscribe(() => {
    //console.log('store.getState()');
    //console.log(store.getState());
    persist.save(obfucateState(store.getState()))
});

export default store;



// helpers
function unobfucateState(stored_state: {[key: string]: any} | null) : AppState | null {

    if(!stored_state) return null;

    let reversed_hash_keys: { [key: string] : string } = {};
    for(let key in HASH_KEYS){
        // @ts-ignore
        reversed_hash_keys[HASH_KEYS[key]] = key;
    }

    let restored_state: any = {};
    for (let hased_k in stored_state) {
        if(stored_state.hasOwnProperty(hased_k)) {
            restored_state[reversed_hash_keys[hased_k]] = stored_state[hased_k];
        }
    }

    return restored_state as AppState;
}


function obfucateState(state: {[key: string]: any}) {
    let new_state: any = {};
    for (let k in state) {
        if(state.hasOwnProperty(k)) {
            // @ts-ignore
            new_state[HASH_KEYS[k]] = state[k];
        }
    }

    return new_state;
}
