// https://www.npmjs.com/package/store2 for localStorage

import store from "store2";
import {STATE_STORE_KEY} from "@/config";


/*
store(key, data);                 // sets stringified data under key
store(key);                       // gets and parses data stored under key
store(key, fn[, alt]);            // run transaction function on/with data stored under key
store({key: data, key2: data2});  // sets all key/data pairs in the object
store();                          // gets all stored key/data pairs as an object
store((key, data)=>{ });          // calls function for each key/data in storage, return false to exit
store(false);                     // clears all items from storage
*/

const persist = {
    clear() {
        store.remove(STATE_STORE_KEY);
    },
    save(data: any) {
        return store(STATE_STORE_KEY, data);
    },
    get() {
        return store(STATE_STORE_KEY);
    }
}

export default persist;
