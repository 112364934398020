import React  from "react";
import {Layout,} from "antd";

const { Content } = Layout;


const SecureFullLayout  = ({children}: {children: any}) => {

    console.log("SecureFullLayout " + Math.random());

    return (
        <Layout style={{ minHeight: '100vh', height: '100%',}}>
            <Content style={{  }}>
                {children}
            </Content>
        </Layout>
    )
};

export default SecureFullLayout;
