import globalHeader from './vi-VN/globalHeader';
import menu from './vi-VN/menu';

type LangMode = {[k: string]: string};

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.page.onloading': 'Vui lòng chờ ...',
  ...globalHeader,
  ...menu
} as LangMode;
