import axios, {AxiosResponse} from "axios";

import {message} from "antd";
import {SERVER_API} from "@/config";
import {APIResponse, ErrorCode} from "@/typings/network.d";
import {getUserCredential} from "@/components/Auth/login";

export type APIResultType<T=any> = {status: 'ok'|'error', data?: T, msg?: string};


// reference: https://www.npmjs.com/package/axios#axios-api
const axios_instance = axios.create({
    baseURL: SERVER_API,
    timeout: 10000,
    /*headers: {
        Authorization: admin_info.jwt, // admin_info.jwt contains client_id & admin_id
    }*/
})


function createConfigHeaderWithCredentials() {
    const user_credentials = getUserCredential();
    if(user_credentials) {
        return {
            headers: {
                Authorization: user_credentials.access_code, // admin_info.jwt contains client_id & admin_id
            }
        }
    }

    return {};
}


function formatAxiosResponse(res: AxiosResponse): APIResultType {

    if(res.status === 500){
        // permission error, redirect to login page
        console.log(' permission error, redirect to login page');

        return {
            status: 'error',
            msg: 'Server return status: '+ res.status,
        }
    }

    if(res.status !== 200){
        return {
            status: 'error',
            msg: 'Server return status: '+ res.status,
        }
    }

    // status = 200
    const api_response: APIResponse = res.data;


    if(api_response.errCode === ErrorCode.UNAUTHORIZED) {

        // force relogin
        window.location.href = '/login?msg=api-unauthorized';
        // message.error('Lỗi xảy ra: Bạn cần đăng nhập lại')

        return {
            status: 'error',
            msg: '',
        }
    }

    if(api_response.errCode === ErrorCode.ERROR) {
        return {
            status: 'error',
            msg: api_response.msg,
        }
    }

    return {
        status: 'ok',
        data: api_response.data,
    }
}


export function notifyApiResult (result: APIResultType, successMsg?: string) : void{
    if(result.status === 'ok') {
        message.success(successMsg || 'Cập nhật thành công', 2)
    }else {
        const error_message = (typeof result.msg == 'string') ? result.msg : JSON.stringify(result.msg);
        message.error('Lỗi xảy ra: ' + error_message, 20)
    }
}


const get = async (endpoint: string, params?: object): Promise<APIResultType> => {

    const credential_config = createConfigHeaderWithCredentials();

    try {

        let res: AxiosResponse = await axios_instance.get(endpoint, {
            params, ...credential_config
        });

        return formatAxiosResponse(res);

    }catch (e) {
        return {
            status: 'error',
            msg: '',
        }
    }
}

const post = async (endpoint: string, data?: object): Promise<APIResultType> => {
    const credential_config = createConfigHeaderWithCredentials();

    try {
        let res: AxiosResponse = await axios_instance.post(endpoint, data, credential_config);

        return formatAxiosResponse(res);
    }catch (e) {
        return {
            status: 'error',
            msg: '',
        }
    }
}

const put = async (endpoint: string, data: object, params?: object): Promise<APIResultType> => {
    const credential_config = createConfigHeaderWithCredentials();

    try {
        let res: AxiosResponse = await axios_instance.put(endpoint, data,{
            params, ...credential_config
        });

        return formatAxiosResponse(res);
    }catch (e) {
        return {
            status: 'error',
            msg: '',
        }
    }
}

const patch = async (endpoint: string, data: any, params?: object): Promise<APIResultType> => {
    const credential_config = createConfigHeaderWithCredentials();

    try {
        let res: AxiosResponse = await axios_instance.patch(endpoint, data, {
            params, ...credential_config
        });

        return formatAxiosResponse(res);
    }catch (e) {
        return {
            status: 'error',
            msg: '',
        }
    }
}

const del = async (endpoint: string, params?: object): Promise<APIResultType> => {
    const credential_config = createConfigHeaderWithCredentials();

    try {
        let res: AxiosResponse = await axios_instance.delete(endpoint, {
            params, ...credential_config
        });

        return formatAxiosResponse(res);

    }catch (e) {
        return {
            status: 'error',
            msg: '',
        }
    }
}

const api = {
    get, post, patch, put, delete: del
}

export default api;


export const logViewItem = async (item_type: string, item_id?: string|number) => {
    await api.post('log/read', {type: item_type, id: item_id});
}


export async function createSingleTag(payload: {tag: string, item_type?: string, item_id?: string|number}) : Promise<boolean> {
    let result = await api.post('tag/create', payload);
    return result.status === 'ok';
}
