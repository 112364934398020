import React from "react";
import {PrivateRouteType} from "@/typings/route";


export const tool_child_routes: PrivateRouteType[] = [
    {
        id: 'tools.home',
        permission_id: 'tools',
        path: '/tool',
        exact: true,
        component: React.lazy(() => import('@/pages/tools')),
    },
    {
        id: 'tools.email',
        permission_id: 'tools.email',
        path: '/tool/email',
        component: React.lazy(() => import('@/pages/tools/email')),
    },
    {
        id: 'tools.landing_page',
        permission_id: 'tools.landing-page',
        path: '/tool/landing-page',
        component: React.lazy(() => import('@/pages/tools/landing_page')),
    },
    {
        id: 'tools.webform',
        permission_id: 'tools.webform',
        path: '/tool/webform',
        component: React.lazy(() => import('@/pages/tools/webform')),
    },
    {
        id: 'tools.minigame',
        permission_id: 'tools.minigame',
        path: '/tool/minigame',
        component: React.lazy(() => import('@/pages/tools/minigame')),
    }
];
