import React from "react";
import {
    DashboardOutlined, FileJpgOutlined, FireOutlined,
    MailOutlined, MessageOutlined, PieChartOutlined,
    SettingOutlined, ContactsOutlined, FormOutlined,
    TeamOutlined, ToolOutlined, GiftOutlined, SmileOutlined, OrderedListOutlined, AreaChartOutlined
} from "@ant-design/icons";

import {Link} from "react-router-dom";
import {HIDE_UNFINISHED} from "@/config";
import {PERMISSION_IDS} from "@/constant/permission_key";


export type MenuType = {
    id: string;
    // permission_id can be shared per routes
    // for example: user allow to view contact/list must be allowed to view contact/list-detail
    // because they share the same entity 'contact'
    permission_id: typeof PERMISSION_IDS[number];

    icon?: React.ReactNode;
    title?: string;
    children?: React.ReactNode;
    authority?: string[];
    submenu?: MenuType[];
    ready_for_production?: boolean;
}


const menu_list_all : MenuType[]  = [
    {
        id: 'dashboard',
        permission_id: 'dashboard',
        icon: <DashboardOutlined />,
        children: <Link to="/">Home</Link>,
        authority: ['owner', 'admin', 'editor', 'viewer'],
        ready_for_production: true
    },
    {
        id: 'contact',
        permission_id: 'contact',
        icon: <ContactsOutlined />,
        title: 'Khách hàng',
        ready_for_production: false,
        submenu: [
            {
                id: 'contact.dashboard',
                permission_id: 'contact',
                icon: <AreaChartOutlined />,
                children: <Link to="/contact">Tổng quan</Link>,
                ready_for_production: false,
            },
            {
                id: 'contact.list',
                permission_id: 'contact.list',
                icon: <OrderedListOutlined />,
                children: <Link to="/contact/list">Danh sách</Link>,
                ready_for_production: true,
            },
        ],
    },

    {
        id: 'tool',
        permission_id: 'tools',
        icon: <ToolOutlined />,
        title: 'Công cụ',
        ready_for_production: true,
        submenu: [
            {
                id: 'tool.email',
                permission_id: 'tools.email',
                icon: <MailOutlined />,
                children: <Link to="/tool/email">Email Marketing</Link>,
                ready_for_production: false,
            },
            {
                id: 'tool.minigame',
                permission_id: 'tools.minigame',
                icon: <GiftOutlined />,
                children: <Link to="/tool/minigame">MiniGame</Link>,
                ready_for_production: true,
            },
            {
                id: 'tool.webpage',
                permission_id: 'tools.landing-page',
                icon: <FireOutlined />,
                children: <Link to="/tool/landing-page">Landing Page</Link>,
                ready_for_production: false,
            },
            {
                id: 'tool.webform',
                permission_id: 'tools.webform',
                icon: <FormOutlined />,
                children: <Link to="/tool/webform">Web Form</Link>,
                ready_for_production: false,
            },
        ],

    },

    {
        id: 'report',
        permission_id: 'report',
        icon: <PieChartOutlined />,
        children: <Link to="/report">Report</Link>,
        authority: ['owner', 'admin'],
        ready_for_production: false,
    },

    {
        id: 'file',
        permission_id: 'files',
        icon: <FileJpgOutlined />,
        children: <Link to="/file">Thư viện file</Link>,
        ready_for_production: true,
    },


    {
        id: 'setting',
        permission_id: 'setup',
        icon: <SettingOutlined />,
        title: 'Cài đặt',
        ready_for_production: true,
        submenu: [
            {
                id: 'setting.about',
                permission_id: 'setup.about',
                icon: <MessageOutlined />,
                children: <Link to="/setup/about">Tài khoản</Link>,
                authority: ['owner', 'admin'],
                ready_for_production: false,
            },
            {
                id: 'setting.user',
                permission_id: 'setup.user',
                icon: <TeamOutlined />,
                children: <Link to="/setup/user">Nhân sự</Link>,
                authority: ['owner', 'admin'],
                ready_for_production: true,
            },
        ],
    },

    {
        id: 'support',
        permission_id: 'support',
        icon: <SmileOutlined />,
        children: <Link to="/support">Support</Link>,
        ready_for_production: false,
    },
];


export const menu_list : MenuType[]  = (HIDE_UNFINISHED) ? _getMenuList(menu_list_all) : menu_list_all;



function _getMenuList(item_list: MenuType[]){
    let new_list: MenuType[] = [];
    item_list.forEach(item => {
        //if(HIDE_UNFINISHED) {
             if(item.ready_for_production) {
                 let copy_item = {...item};
                 if(item.submenu && item.submenu?.length > 0) {
                     copy_item.submenu = [..._getMenuList(item.submenu)];
                 }

                 new_list.push(copy_item);
             }
        //}else{
            //new_list.push({...item});
       // }
    })

    return new_list;
}

