import React from "react";
import {PrivateRouteType} from "@/typings/route";


export const setup_child_routes: PrivateRouteType[] = [
    {
        id: 'setup.home',
        permission_id: 'setup',
        path: '/setup',
        exact: true,
        component: React.lazy(() => import('@/pages/setup')),
    },
    {
        id: 'setup.about',
        permission_id: 'setup.about',
        path: '/setup/about',
        component: React.lazy(() => import('@/pages/setup/about')),
    },
    {
        id: 'setup.user',
        permission_id: 'setup.user',
        path: '/setup/user',
        component: React.lazy(() => import('@/pages/setup/user')),
    }
];
