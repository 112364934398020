import { DropDownProps } from 'antd/es/dropdown';
import { Dropdown } from 'antd';
import React from 'react';
import classNames from 'classnames';

import styles from './index.less';

declare type OverlayFunc = () => React.ReactNode;

// @ts-ignore
export interface HeaderDropdownProps extends DropDownProps {
  overlayClassName?: string;
  //menu: React.ReactNode | OverlayFunc;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter';
}


const HeaderDropdown: React.FC<HeaderDropdownProps> = ({ overlayClassName: cls, ...restProps }) => (
    // @ts-ignore
    //<Dropdown overlayClassName={classNames(cls)} {...restProps} />
    <Dropdown overlayClassName={classNames(styles.container, cls)} {...restProps} />
);

export default HeaderDropdown;
