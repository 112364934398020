import {MenuType} from "@/constant/menu";
import {AuthorityType, PermissionLevel} from "@/typings/auth";
import api from "@/lib/api";
import debug from "@/lib/debug";
import {PrivateRouteType} from "@/typings/route";
import {PERMISSION_ID_TYPE} from "@/constant/permission_key";


// global property to check for user-permission on each module on this app
// user_type is for control: view all (ie. owner and admin can view all) or view some modules
// entity_permission controls: what to view and other permission level: create/update/delete
// IMPORTANT: since a user can find a way to hack this code (ie. to view page that he/she is not allowed to), it's NOT important since we do check again on the back-end whether the user is allowed to take that action (view/create/update/delete) and then load content or perform action on backend accordingly.
// if user is not allow to view a page then the backend just returns a empty info (even the user has managed to hack this permission on front-end successfully)
let user_permission : {
    user_type: AuthorityType | 'viewer';
    entity_permission : {
        /*
            Format:
            module => PermissionLevel,
            module.sub_module => PermissionLevel,

            For example in DB we store user's entity_permission for contact as follows:
            contact: {
                list: {view: true, create: true, update: false, delete: false},
                segment: {view: true, create: true, update: false, delete: false},
            }

            Will be translated in the front-end app as follows:
            contact: {view: true},
            contact.list: {view: true, create: true, update: false, delete: false},
            contact.segment: {view: true, create: true, update: false, delete: false},

            Therefore, if there is any issue on user-permission: make sure the route has the ID the same as the module or module.sub_module

            Reference to the api/config/admin.entity.permission.php for exact module/submodule name
        */

        [key: string]: PermissionLevel;
    }
} = {user_type: 'viewer', entity_permission: {}}


export function getPermissionLevel(id: PERMISSION_ID_TYPE) : PermissionLevel {
    return  {
        view    : checkCreatePermit(id),
        create  : checkCreatePermit(id),
        delete  : checkDeletePermit(id),
        update  : checkUpdatePermit(id),
    }
}

export async function setMyPermission() : Promise<any> {
    const result = await api.get("admin/my-permission");
    if (result.status === 'ok') {
        // result.data = {"user_type":"editor","entity_permission":{"file":{"file":{"view":true}},"contact":{"list":{"view":true}},"tools":{"minigame":{"view":true}}}
        user_permission = {
            user_type: result.data.user_type,
            entity_permission: {..._translateDBEntityPermission(result.data.entity_permission)},
        };

        debug.log('user_permission', user_permission);
    }


    function _translateDBEntityPermission(db_entity_permission: any) {
        let result: {[key: string]: PermissionLevel} = {};
        Object.keys(db_entity_permission).map((parent_id) => {
            // parent is viewable
            result[parent_id] = {view: true};
            // now children
            Object.keys(db_entity_permission[parent_id]).map((child_id) => {
                result[`${parent_id}.${child_id}`] = db_entity_permission[parent_id][child_id];
            })
        })

        return result;
    }
}


export const checkCreatePermit = (id: PERMISSION_ID_TYPE) : boolean => {

    // owner and admin can view all
    if(['owner', 'admin'].includes(user_permission.user_type)) return true;

    // check entity_permission
    if(user_permission.entity_permission.hasOwnProperty(id)) {
        return user_permission.entity_permission[id].create || false;
    }

    return false;
}

export const checkUpdatePermit = (id: PERMISSION_ID_TYPE) : boolean => {

    // owner and admin can view all
    if(['owner', 'admin'].includes(user_permission.user_type)) return true;

    // check entity_permission
    if(user_permission.entity_permission.hasOwnProperty(id)) {
        return user_permission.entity_permission[id].update || false;
    }

    return false;
}

export const checkDeletePermit = (id: PERMISSION_ID_TYPE) : boolean => {

    // owner and admin can view all
    if(['owner', 'admin'].includes(user_permission.user_type)) return true;

    // check entity_permission
    if(user_permission.entity_permission.hasOwnProperty(id)) {
        return user_permission.entity_permission[id].delete || false;
    }

    return false;
}

export const checkViewPermit = (id: PERMISSION_ID_TYPE) : boolean => {

    // owner and admin can view all
    if(['owner', 'admin'].includes(user_permission.user_type)) return true;

    // check entity_permission
    if(user_permission.entity_permission.hasOwnProperty(id)) {
        return user_permission.entity_permission[id].view || false;
    }

    return false;
}


export const checkRouteViewPermit = (route: PrivateRouteType) : boolean => {

    // global route

    // home
    if(route.path && route.path == '/') {
        return true;
    }

    // check route authority
    if(route.authority && (route.authority.length === 0 || route.authority.includes(user_permission.user_type))) {
        return true;
    }

    return checkViewPermit(route.permission_id);
}



export const checkMenuViewPermit = (menu: MenuType) : boolean => {
    let view_permission = false;

    if(menu.submenu && menu.submenu.length > 0) {
        // if can view any sub-menu, can view the parent
        menu.submenu.forEach((child_menu) => {
            if(checkViewPermit(child_menu.permission_id)){
                view_permission = true;
                return;
            }
        })
    }else{
        view_permission = menu.permission_id == 'dashboard' || checkViewPermit(menu.permission_id) || false;
    }

    return view_permission;
}
