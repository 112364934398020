import React, { useState, useContext, createContext } from 'react';
import store from "store2";
import {encode, decode} from 'js-base64';
import {getCurrentUTCTimestamp} from "@/lib/utils";
import {CREDENTIAL_SAVE_KEY} from "@/config";
import api from "@/lib/api";
import {UserInfo} from "@/typings/user";
import {actions} from "@/store/actions";
import {default as storeState} from "@/store";
import {setUp} from "@/setup";
//import {encrypt, decrypt} from "@/lib/encryption";


export type UserCredentialType = {
    user_id     : string;
    client_id   : string;
    access_code : string;
}


const authContext = createContext<any>(undefined);


export const useAuth = () => useContext(authContext);


export function ProvideAuth({ children }: { children: React.ReactNode }) {
    const auth = useProvideAuth();

    return (
        <authContext.Provider value={auth} >
            { children }
        </authContext.Provider>
    );
}


export const getUserCredential = (): UserCredentialType & {t: number} | undefined => {
    if(store.has(CREDENTIAL_SAVE_KEY)) {
        return JSON.parse(decode(store.get(CREDENTIAL_SAVE_KEY)));
    }

    return undefined;
}


const saveUserCredential = (user_data: UserCredentialType)  => {
    const store_data = {...user_data, t: getCurrentUTCTimestamp()}
    store(CREDENTIAL_SAVE_KEY, encode(JSON.stringify(store_data)));
}


function useProvideAuth() {

    const stored_user_data  = getUserCredential();
    const CURRENT_TIME      = getCurrentUTCTimestamp();

    if(stored_user_data) {
        try {

            // force user to re-login after 7 days
            if(!stored_user_data.hasOwnProperty('t') || stored_user_data['t'] < CURRENT_TIME - 7 * 24 * 3600) { // 7 * 24 * 3600
                store.remove(CREDENTIAL_SAVE_KEY);
            }

        }catch (e) {
            //
        }
    }

    const [user, setUser] = useState<UserCredentialType>(stored_user_data || {user_id: '', client_id: '', access_code: '' }) ;

    const signIn = async (user_data: UserCredentialType, cb?: Function) => {

        setUser(user_data);

        saveUserCredential(user_data);

        // get user info and store in redux
        const result_info = await api.get('admin/personal-info');

        if(result_info.status === 'ok' && result_info.data) {
            const user_info = result_info.data as UserInfo;

            const updated_info = {
                id          : user_data.user_id,
                name        : [user_info.last_name, user_info.first_name].join(" "),
                avatar      : user_info.avatar,
                user_type    : user_info.user_type,
                upload_token : user_info.upload_token,
                last_password_login_time: getCurrentUTCTimestamp()
            };

            storeState.dispatch(actions.changeUserInfo(updated_info));
        }

        // setting up account
        await setUp();

        //if(cb) cb();
    }

    const signOut = (cb?: Function) => {
        store.remove(CREDENTIAL_SAVE_KEY);
        if(cb) cb();
    }

    return {
        user,
        signIn,
        signOut
    }
}
