import {Layout} from "antd";
import React from "react";

const { Footer } = Layout;


const GlobalFooter = () => {
    return (
        <Footer style={{ textAlign: 'center' }}>
            adMan © 2023 by <a href={'https://www.hurasoft.vn'} target={'_blank'} rel="noreferrer">HuraSoft</a>
        </Footer>
    )
}

export default GlobalFooter;
