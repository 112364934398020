import {message} from "antd";

import {REDIRECTOR_URL} from "@/config";
import memoizeOne from 'memoize-one';
import api from "@/lib/api";
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from "moment/moment";


export {isEmpty, isEqual};

// copy from https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript
export function isObject(a: any) {
    return (!!a) && (a.constructor === Object);
}

export function isArray(a: any) {
    return (!!a) && (a.constructor === Array);
}

export function buildNumberRange(from: number, to: number) {
    let list = [];
    for(let i = from; i <= to; i ++ ){
        list.push(i);
    }

    return list;
}

/*
* util to change if a part of any object (fullInfo) has values changed (i.e. in form - which is changedInfo)
* if nothing changes, we might not need to submit the form to the backend
*/
export function checkIfObjectChangeValue(
    fullInfo: {[key: string]: any},
    changedInfo: {[key: string]: any},
    excluded_keys: string[]
) {

    const extractObjectValues = (key_set: string[], item: {[key: string]: any}) => {
        let result: {[key: string]: any} = {};
        key_set.map((key) => {
            result[key] = item[key];
        })

        return result;
    }

    const getChangedKeys = () => {
        return Object.keys(changedInfo).filter(value => !excluded_keys.includes(value));
    }

    const examined_keys = getChangedKeys();

    return !isEqual(extractObjectValues(examined_keys, changedInfo), extractObjectValues(examined_keys, fullInfo));
}


export function copyToClipboard(text: string) {
    if (!navigator.clipboard) {
        message.error('Trình duyệt không hỗ trợ');
        return;
    }

    navigator.clipboard.writeText(text).then(function() {
        //console.log('Async: Copying to clipboard was successful!');
        message.info('Đã copy');
    }, function(err) {
        message.error('Trình duyệt không hỗ trợ');
        //console.error('Async: Could not copy text: ', err);
    });
}

export function getTimeFormatForForm(timestamp?: number) {

    if(!timestamp || timestamp < 100) return '';

    return moment(moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss'));
}

export function object_difference(object: object, base: object) : object {

    return _changes(object, base);

    function _changes(object: object, base: any) : object {
        // @ts-ignore
        return _.transform(object, function(result, value, key) {
            // @ts-ignore
            if (!_.isEqual(value, base[key])) {
                // @ts-ignore
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? _changes(value, base[key]) : value;
            }
        });
    }

}

export function clearObjectUndefinedValues(obj: {[key: string] : any}) {
    return pickBy(obj, (v, k) => v !== undefined);
}

export const setOnloading = (fn: () => Promise<void>) => {

    _setPageOnLoading( true);

    fn().then(() => {
        _setPageOnLoading(false);
    });

    function _setPageOnLoading (status: boolean) {
        if(status) {
            message.loading({
                content: 'Loading',
                key: 'pageonloading',
                duration: 0
            });
        } else {
            message.destroy();
        }
    }
};

export const formatNumber = memoizeOne(_formatNumber_raw);

//format a number for readability
//1000 => 1.000
//-1000 => -1.000
function _formatNumber_raw (num?: number) : string{
    if(!num || num === 0) return '0';

    const is_negative_number = (num < 0);

    let str = (is_negative_number) ? (num * -1) + '' : num + ''; //convert to string
    let char_count = str.length;
    if(char_count <= 3) {
        return (is_negative_number) ? '-' + str : str;
    }

    let first_part = str.substr(0, char_count % 3); // num = 10000 => this part = 10
    let remain_part = str.replace(first_part, "");
    let num_group = Math.round(remain_part.length/3);

    let parts = [];
    if(first_part !== '') parts.push( first_part ); // num = 10000 => this part = 10

    for (let i = 0; i < num_group; i++){
        parts.push( remain_part.substr( i*3, 3));
    }

    return (is_negative_number) ? '-' + parts.join('.') : parts.join('.');
}


export function isBrowserSupport(): boolean {
    // check support for indexedDB to store various async data
    if (!window.indexedDB) return false;

    // check localstorage support for redux store persist
    if ( ! _localStorageAvailable()) return false;

    // other
    // ...

    return true;


    // helpers

    // shamelessly copied from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    function _localStorageAvailable() {
        let storage;
        try {
            // @ts-ignore
            storage = window['localStorage'];
            let x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                    // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    }
}


// runUpdateAdminStatus periodically
export const runUpdateAdminStatus = (admin_id: string, interval: number = 10) => {

    _run();

    // then check periodically
    setTimeout(function () {
        runUpdateAdminStatus(admin_id, interval);
    }, interval * 1000);

    function _run(){
        api.post("admin/update-status", {admin_id: admin_id, connected: true}) ;
    }
}

// check user's internet connection periodically
export const runCheckNetworkConnection = (interval: number = 10, cb: (isOnline: boolean) => void ) => {
    // check onload
    _runCheck();

    // then check periodically
    setTimeout(function (){
        runCheckNetworkConnection(interval, cb);
    }, interval * 1000);

    function _runCheck(){
        checkUserInternetConnection().then(cb);
    }
}


export function confirmLeavePage() {
    window.addEventListener("beforeunload", function (e) {
        let confirmationMessage = "Thay đổi trang sẽ mất dữ liệu hiện tại";
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;              // Gecko, WebKit, Chrome <34
    });
}

export const showUnixTime = memoizeOne(_showUnixTime_raw);

function _showUnixTime_raw(timestamp_in_utc: number|undefined) {
    //(t) ? dayjs.unix(t).format('DD-MM-YYYY h:mma') : '';
    if(!timestamp_in_utc) return '';

    const a = new Date(timestamp_in_utc * 1000);
    //let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const months = ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'];

    const year = a.getFullYear();
    const month = months[a.getMonth()];

    const date = a.getDate();
    const date_string = (date < 10) ? "0"+date  : date;

    const hour = a.getHours();
    const hour_text = (hour > 9) ? hour : '0' + hour;

    const min = a.getMinutes();
    const min_text = (min > 9) ? min : '0' + min;
    //let sec = a.getSeconds();

    return [date_string, month, year + " " + hour_text + ':' + min_text].join("-");
}


export function getRandomInt(min: number, max: number) : number {
    let floor_min = Math.ceil(min);
    let floor_max = Math.floor(max);
    return Math.floor(Math.random() * (floor_max - floor_min + 1)) + floor_min;
}

// check whether user's connection is ok
// ref: https://github.com/sindresorhus/is-online
// https://www.npmjs.com/package/public-ip
export async function checkUserInternetConnection(){
    return true;
}


// get current timestamp in UTC
export function getCurrentUTCTimestamp(microtime: boolean = false) : number {
    let x = new Date();
    let micro_time = x.getTime() + x.getTimezoneOffset() * 60 * 1000;
    return (microtime) ? micro_time : Math.floor(micro_time / 1000);
}


export function maskExternalUrl(url: string) {
    return `${REDIRECTOR_URL}?url=${encodeURIComponent(url)}`;
}


// given an array, keep max_size latest items and discard other. return new array
export function keepMaxArraySize(arr: any[], max_size: number) {
    let arr_size = arr.length;

    // no change
    if(arr_size <= max_size) return arr;

    let copied_arr = [...arr];
    copied_arr.splice(0, copied_arr.length - max_size);

    return copied_arr;
}



export function findObjectInArray(arr: { [key: string]: any }[], key: string, value: any) : {index: number, item: object} {
    let item: object = {};
    let match_index = -1;
    for ( let index = 0; index < arr.length; index ++) {
        if(arr[index][key] === value) {
            item = {...arr[index]};
            match_index = index;
            break;
        }
    }

    return {
        index: match_index,
        item: item
    };
}


export function createUserId(): string {
    return Math.random().toString(36).slice(2);
}

export function currentTimestamp() : number {
    return Date.now() / 1000;
}


/**
 * @date 26-02-2016
 * @author Hieu
 * @description: count number of items in object
 * @usage example
 */
export function objectSize( content: object ) {
    let length = 0;
    for( let key in content ) {
        if( content.hasOwnProperty(key) ) {
            length ++;
        }
    }
    return length;
}


/**
 * @date 09-03-2016
 * @author http://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
 * @description: find url in text and replace with clickable a
 * @usage
 */
export function formatUrl(text: string) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, '<a href="//www.chatngay.com/redirect.php?url=$1" target="_blank">$1</a>')
}


/**
 * @date 03-03-2016
 * @author http://stackoverflow.com/questions/4959975/generate-random-value-between-two-numbers-in-javascript
 * @description: get a random number between min-max
 * @usage example
 */
export function randomBetween(min: number, max: number) {
    return Math.floor( Math.random() * ( max - min + 1) + min);
}


/**
 * @date 21-02-2016
 * @author http://youmightnotneedjquery.com/
 * @description: trim a string, support IE8+
 * @param str
 * @return string
 * @usage example
 trim(str);
 */
export function trim(str: string){
    if (!String.prototype.trim) {
        //in case of IE 8 or lower
        return str.replace(/^\s+|\s+$/g, '') ;
    }else{
        return str.trim();
    }
}


/**
 * @date 22-02-2016
 * @author Hieu
 * @description: shorten a string by char count
 * @usage example
 subStr(str, char_count)
 */
export function subStr (str: string | undefined, char_count: number = 30): string {
    if(!str) return '';
    let padding = ' ...';
    let result = '';
    let cut = str.indexOf(' ', char_count);
    if(cut === -1) result = str;
    else result = str.substring(0, cut);
    return (result.length <= char_count) ? result : result.substring(0, char_count) + padding;
}


/**
 * https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
 */
export function isMobile(){
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}
