import React, {useState} from "react";
import {Link } from "react-router-dom";
import api from "@/lib/api";
import {Button, Form, Input} from "antd";
import {BankOutlined, IeOutlined, MailOutlined, MobileOutlined, UserOutlined} from "@ant-design/icons";
import debug from "@/lib/debug";


export const RegisterForm = () => {

    const [registerStatus, setRegisterStatus]    = useState<number>(0);
    const [registerMessage, setRegisterMessage]    = useState<string>('');


    const onFinish = async (values: any) => {

        debug.log('RegisterForm', values);
        //return;

        setRegisterStatus(1);

        //console.log('Received values of form: ', values);
        const result = await api.post('register/register', values);

        debug.log('result', result);
        //return;

        if(result.status === 'error') {
            setRegisterMessage(result.msg || '');
            setRegisterStatus(0);
            return;
        }

        setRegisterStatus(2);
    }


    if(registerStatus == 2) {
        return (
            <>
                <h2>Bước cuối cùng</h2>

                <p>Vui lòng kiểm tra email bạn vừa đăng ký để kích hoạt và hoàn thành việc đăng ký tài khoản.</p>

                <p>Lưu ý: Email có thể đến chậm 1 vài phút. Quý khách có thể cần kiểm tra cả hộp thư rác nếu cần thiết để tìm email được gửi từ noreply@adman.vn</p>
            </>
        )
    }


    return (
        <>

            <h2>Đăng ký tài khoản adMan</h2>
            <p>Rất dễ dàng để tạo tại khoản adMan cho doanh nghiệp của bạn và triển khai chiến dịch marketing.</p>

            <div style={{marginTop: 30}}>
                <Form
                    name="normal_login"
                    className="login-form"
                    //initialValues={{ remember: true }}
                    onFinish={onFinish}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 10 }}
                >

                    <Form.Item
                        label={'Tên doanh nghiệp'}
                        name="company_name"
                        rules={[
                            { required: true, message: 'Chưa nhập' }
                        ]}
                    >
                        <Input prefix={<BankOutlined className="site-form-item-icon" />} placeholder="Tên doanh nghiệp" />
                    </Form.Item>

                    <Form.Item
                        label={'Website'}
                        name="website"
                        rules={[
                            { required: true, message: 'Chưa nhập' }
                        ]}
                    >
                        <Input prefix={<IeOutlined className="site-form-item-icon" />} placeholder="Website" />
                    </Form.Item>

                    <Form.Item
                        label={'Tên của bạn'}
                        name="name"
                        rules={[
                            { required: true, message: 'Chưa nhập' }
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Tên" />
                    </Form.Item>

                    <Form.Item
                        label={'Email của bạn'}
                        name="email"
                        rules={[
                            { required: true, message: 'Chưa nhập' },
                            { type: 'email', message: 'Email chưa đúng' }
                        ]}
                    >
                        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                        label={'Số Mobile'}
                        name="mobile"
                        rules={[
                            { required: true, message: 'Chưa nhập' }
                        ]}
                    >
                        <Input prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="Mobile" />
                    </Form.Item>


                    <Form.Item
                        label={' '}
                        colon={false}
                    >
                        {
                            (registerMessage != "") && (
                                <div style={{color: 'red'}}>
                                    Lỗi xảy ra: {registerMessage}
                                </div>
                            )
                        }

                        <Button loading={registerStatus === 1} type="primary" htmlType="submit" className="login-form-button">
                            Đăng ký
                        </Button>
                    </Form.Item>

                </Form>

            </div>

            <div style={{marginTop: 20}}>
                    <span>
                        Nếu bạn đã đăng ký, vui lòng <Link to={'/login'}>đăng nhập</Link>
                    </span>
            </div>
        </>
    );
}

