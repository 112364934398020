import {Layout, Row, Col } from "antd";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import React from "react";
import {useAuth} from "@/components/Auth/login";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "@/store/typing";
import {actions} from "@/store/actions";
import RightContent from "./RightContent";

const { Header } = Layout;


const GlobalHeader = () => {

    const auth      = useAuth();
    const history   = useHistory();
    const {side_menu_collapse} = useSelector((state: AppState) => ({
        // adminInfo: state.user_info,
        side_menu_collapse: state.side_menu_collapse
    }));

    const logout = () => {
        auth.signOut(() => {
            history.replace("/login");
        });
    }

    const dispatch = useDispatch();
    const setCollapsed = () => {
        dispatch(actions.triggerSideMenuCollapse(!side_menu_collapse))
    }

    return (
        <Header
            className="site-layout-background"
            style={{ paddingLeft: 10, position: 'fixed', zIndex: 100, width: '100%' }}
        >

            <Row>
                <Col span={12}>

                    {/*
                    see https://4x.ant.design/components/layout/#components-layout-demo-side
                    */}
                    {React.createElement(side_menu_collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: setCollapsed,
                    })}

                </Col>
                <Col span={12}>

                    <RightContent />

                    {/*<div style={{textAlign: 'right', paddingRight: 170}}>
                        <Avatar src={adminInfo.avatar} />
                        {adminInfo.name}

                        <Divider type={'vertical'} />

                        <span className={'a-link'} onClick={logout}>
                            Log-out
                        </span>
                    </div>*/}

                </Col>
            </Row>

        </Header>
    )
}

export default GlobalHeader;
