import React, {Suspense, useState} from "react";
import Loading from "@/components/Loading";
import {Button, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "@/store/typing";
import {actions} from "@/store/actions";
import store from "@/store";

import {componentMap} from "./component_map";
import {ModalComponentType} from "./typings";
import {LoadingOutlined} from "@ant-design/icons";


export const openModal = (component: ModalComponentType, args: any) => {
    store.dispatch(actions.openGlobalModal({component, args}))
}


const ModalComponent: React.FC = () => {

    const dispatch = useDispatch();
    const closeModal = () => {
        setSubmitResult(null);
        dispatch(actions.openGlobalModal({component: ''}));
    }
    const global_modal = useSelector((state: AppState) => state.global_modal);
    const modal_component = (global_modal !== '') ? JSON.parse(global_modal) : {component: '', args: {}};

    const [submitResult, setSubmitResult]   = useState<any>(null);
    const [submitting, setSubmitting]       = useState<boolean>(false);

    // @ts-ignore
    const getMap: {title: string, component: React.LazyExoticComponent<any>} | null = componentMap[modal_component.component];

    const getLoadedComponent = () => {
        if(getMap) {
            let Component: React.LazyExoticComponent<any>|null = getMap.component;
            return <Component
                        {...modal_component.args}
                        // for component using form
                        submitting={submitting}
                        onSubmitSuccess={(submitResult: any) => {
                            setSubmitting(false);
                            setSubmitResult(submitResult);
                        }}
                        onFinishFailed={() => setSubmitting(false)}
                    />;
        }

        return null;
    }

    const Title = () => {
        if(getMap) {
            return <>{getMap.title}</>;
        }

        return null;
    }

    const onOK = () => {
        setSubmitting(true);
    }

    const modalFooter = submitting
        ? { footer: <Button icon={<LoadingOutlined />} type="primary">Đang xử lý</Button>, onCancel: closeModal }
        : (
            submitResult
                ? { footer: <Button type="default" onClick={closeModal}>Đóng lại</Button> }
                : { okText: 'Thực hiện', onOk: onOK , onCancel: closeModal }
          ) ;


    const displayFormResult = () => {
        if(!submitResult || submitResult === '') {
            return null;
        }

        return (
            <div style={{textAlign: 'center'}}>
                {
                    typeof submitResult == 'string' ? submitResult : JSON.stringify(submitResult)
                }
            </div>
        )
    }


    return (
        <Modal
            title={<Title />}
            centered
            open={modal_component.component !== ''}
            width={1000}
            maskClosable={false}
            {...modalFooter}
            destroyOnClose
        >
            <Suspense fallback={<Loading />}>
                {
                    getLoadedComponent()
                }
            </Suspense>

            { displayFormResult() }

        </Modal>
    )
};


export default ModalComponent;
