import React from "react";
import {PrivateRouteType} from "@/typings/route";


export const report_child_routes: PrivateRouteType[] = [
    {
        id: 'report.home',
        permission_id: 'report',
        path: '/report',
        component: React.lazy(() => import('@/pages/Report')),
    },
    {
        id: 'report.contact',
        permission_id: 'report',
        path: '/report/contact',
        component: React.lazy(() => import('@/pages/Report/ReportCustomer')),
    },
];
