import {PrivateRouteType} from "@/typings/route";

const UnAuthorized = ({route}: {route: PrivateRouteType}) => {
    return (
        <>
            <h2>Lỗi: không được phép</h2>
            <p>Bạn chưa được phân quyền để sử dụng chức năng này. Vui lòng liên hệ quản trị cấp cao hơn để được phân quyền.</p>
            <p>Link liên quan: {route.path}</p>
            <p>RouteID/Permission: {route.id}/{route.permission_id}</p>
        </>
    )
}

export default UnAuthorized;