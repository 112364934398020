import React  from 'react';
import {Layout, Menu} from 'antd';
import {useLocation} from "react-router-dom";

import GlobalModal from '@/components/GlobalModal';
import GlobalDrawer from "@/components/GlobalDrawer";
import GlobalHeader from "@/components/GlobalHeader";
import GlobalFooter from "@/components/GlobalFooter";

import '@/styles/app.css';

import {menu_list} from "@/constant/menu";
import {checkMenuViewPermit} from "@/components/Auth";
import {useSelector} from "react-redux";
import {AppState} from "@/store/typing";

//import logo_scr from '@/assets/logo.png';
const logo_scr = require('@/assets/logo.png');

const { Content, Sider } = Layout;
const { SubMenu } = Menu;


const getOpenMenu = (pathname: string) : string[] => {

    if(pathname === '/') {
        return ['dashboard'];
    }

    return pathname.split('/').filter((item) => item.length > 0);
}


const SecureLayout = ({children}: {children: any}) => {

    const location = useLocation();

    const side_menu_collapse = useSelector((state: AppState) => state.side_menu_collapse);
    const layout_class_name = (side_menu_collapse) ? 'site-layout collapse' : 'site-layout un-collapse';
    const logo_class_name = (side_menu_collapse) ? 'logo collapse' : 'logo un-collapse';

    return (

        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                collapsible
                collapsed={side_menu_collapse}
                trigger={null}
                style={{
                   overflow: 'auto',
                   height: '100vh',
                   position: 'fixed',
                   left: 0,
               }}
            >
                <div className={logo_class_name}>
                    <img alt="adMan" src={logo_scr} />
                </div>

                <Menu theme="dark" defaultSelectedKeys={getOpenMenu(location.pathname)} mode="inline">

                    {
                        menu_list
                            .filter( (menu) => checkMenuViewPermit(menu))
                            .map((item) => {

                                // parent
                                if(item.submenu && item.submenu.length > 0) {
                                    return (
                                        <SubMenu key={item.id} icon={item.icon} title={item.title}>
                                            {
                                                item.submenu
                                                    .filter( (child) => checkMenuViewPermit(child))
                                                    .map(child => (
                                                        <Menu.Item key={child.id} icon={child.icon}>
                                                            {child.children}
                                                        </Menu.Item>
                                                    ))
                                            }
                                        </SubMenu>
                                    )
                                }

                                return (
                                    <Menu.Item key={item.id} icon={item.icon}>
                                        {item.children}
                                    </Menu.Item>
                                )
                            })
                    }

                    {/*<Menu.Item
                        key="guide"
                        title={'Hướng dẫn sử dụng'}
                        icon={<QuestionOutlined />}
                    >
                        <Link to="/guide">Hướng dẫn</Link>
                    </Menu.Item>*/}

                </Menu>
            </Sider>

            <Layout className={layout_class_name}>

                <GlobalHeader />

                <Content style={{ margin: '50px 16px 0 16px' }}>

                    {/*<Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>User</Breadcrumb.Item>
                        <Breadcrumb.Item>Bill</Breadcrumb.Item>
                    </Breadcrumb>*/}

                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360, marginTop: 20 }}>

                        {children}

                    </div>

                </Content>

                <GlobalFooter />

            </Layout>

            <GlobalModal />
            <GlobalDrawer />

        </Layout>
    )
};

export default SecureLayout;
