import React  from "react";
import {Col, Layout, Row} from "antd";
//import styles from '@/assets/login.css';

const { Content } = Layout;

const logo_scr = require('@/assets/logo.png');


const LoginLayout  = ({children}: {children: any}) => {

    console.log("LoginLayout random " + Math.random());


    return (

        <Layout style={{ minHeight: '100vh', height: '100%', backgroundColor: 'white' }}>

            <Content style={{  }}>

                <Row>
                    <Col style={{backgroundColor: '#070707', height: window.innerHeight, textAlign:'center', width: '360px' }} >

                        <div style={{marginTop: 200}}><img alt="adMan" src={logo_scr} /></div>
                        <div style={{marginTop: 10, fontSize: 25, color: 'white'}}>Marketing Made Easy!</div>

                    </Col>

                    <Col style={{paddingLeft: 20}} >

                        <div style={{ paddingLeft: 10, maxWidth: 700, marginTop: 100 }}>

                            {children}

                            <div style={{ marginTop: 20 }}>
                                ©2023 <a href={'https://www.hurasoft.vn'} target={'_blank'} rel="noreferrer">HuraSoft</a>
                            </div>

                        </div>

                    </Col>
                </Row>


            </Content>

        </Layout>
    )
};

export default LoginLayout;
