import React from 'react';
import Avatar from './components/User/AvatarDropdown';
import HeaderSearch from './components/HeaderSearch';
import NoticeIconView from "./components/User/NoticeIconView";

import styles from "./components/User/index.less";
import {useSelector} from "react-redux";
import {AppState} from "@/store/typing";
//import {useHistory} from "react-router-dom";
import {HIDE_UNFINISHED} from "@/config";


interface GlobalHeaderRightProps {}


const GlobalHeaderRight: React.FC<GlobalHeaderRightProps> = props => {
  //const { theme } = props;
  //let className = styles.right;

  //if (layout === 'topmenu') {
    //className = `${styles.right}  ${styles.dark}`;
  //}

  console.log('styles.right', styles.right);

  //const history   = useHistory();

  const side_menu_collapse = useSelector((state: AppState) => state.side_menu_collapse);

  return (
    <div id={'id-GlobalHeaderRight'} style={{
        float: 'right',
        height: '100%',
        marginRight: (side_menu_collapse) ? 50 : 170,
        overflow: 'hidden',

    }} className={styles.right}>
      <HeaderSearch
        defaultOpen={true}
        className={`${styles.action} ${styles.search}`}
        placeholder={'Search'}
        defaultValue=""
        /*dataSource={[
            /!*'keyword 1',
            'keyword 2',*!/
        ]}*/
        onSearch={value => {
            // fetch suggestion
          console.log('input onSearch=', value);
        }}
        /*onPressEnter={value => {
            console.log('onPressEnter=', value);
            //alert(value);
            history.replace(`/search?q=${encodeURIComponent(value)}`)
        }}*/
        onChange={(value: string) => {
            //
        }}
        //onVisibleChange={(b) => {}}
      />

      {/*<Tooltip
        title={formatMessage({
          id: 'component.globalHeader.help',
        })}
      >
        <a
          target="_blank"
          href="/help"
          rel="noopener noreferrer"
          className={styles.action}
        >
          <Icon type="question-circle-o" />
        </a>
      </Tooltip>*/}

        {!HIDE_UNFINISHED &&  <NoticeIconView /> }

      <Avatar />

    </div>
  );
};

export default GlobalHeaderRight;
