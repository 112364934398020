import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import queryString from "query-string";
import {Button, Form, Input} from "antd";
import {LockOutlined} from "@ant-design/icons";
import api, {APIResultType} from "@/lib/api";
import Loading from "@/components/Loading";


type SetupSuccess = {
    client_id?: string;
    user_id?: string|null;
    user_password?: string|null;
}

const SettingAccount = ({email, token, setStep}: {email: string; token: string; setStep: (step: string) => any}) => {

    const [isWaiting, setWaiting] = useState<boolean>(true);
    const [setupResult, setSetupResult] = useState<APIResultType<SetupSuccess>>({status: 'error'});

    console.log([email, token]);

    useEffect( () => {

        _setup();

        async function _setup() {
            const result: APIResultType<SetupSuccess> = await api.post("register/setup-account", {email, token});
            console.log(result);
            setWaiting(false);
            setSetupResult(result);
        }

    }, [email, token])


    if(!isWaiting) {

        const VerifyResult = () => {

            if(setupResult.status == 'error') {
                return (
                    <div style={{color: 'red'}}>Lỗi xảy ra. Link lỗi hoặc đã được sử dụng.</div>
                )
            }

            return (
                <>
                    <div>Tài khoản đã được tạo thành công. </div>

                    <div style={{marginTop: 20, marginBottom: 20}}>Mật khẩu tạm thời để đăng nhập lần đầu là: <strong style={{fontSize: 16}}>{setupResult.data?.user_password}</strong></div>

                    <div>Vui lòng <Link to={'/login'}>đăng nhập</Link> và đổi mật khẩu.</div>
                </>

            )
        }


        return (
            <>
                <h2>Kết quả xác minh</h2>

                <VerifyResult />
            </>
        )
    }



    return (
        <>
            <h2>Đang xác minh tài khoản</h2>

            <Loading />
        </>
    )
}


const SetPassword = () => {

    const [registerStatus, setRegisterStatus]    = useState<number>(0);
    const [registerMessage, setRegisterMessage]    = useState<string>('');
    const location  = useLocation();

    const {email, tk: token} = queryString.parse(location.search);

    console.log([email, token]);

    const onFinish = async (values: any) => {

        console.log('RegisterForm', values);
        //return;

        /*setRegisterStatus(1);

        //console.log('Received values of form: ', values);
        const result = await api.post('register/set-password', values);

        console.log('result', result);

        if(result.status === 'error') {
            setRegisterMessage(result.msg || '');
            setRegisterStatus(0);
            return;
        }

        setRegisterStatus(2);*/
    }


    return (
        <>
            <h2>Hoàn tất đăng ký</h2>

            <p>Tài khoản của bạn đã được thiết lập thành công. Vui lòng cài đặt mật khẩu để đăng nhập:</p>

            <div style={{marginTop: 30}}>
                <Form
                    name="normal_login"
                    className="login-form"
                    //initialValues={{ remember: true }}
                    onFinish={onFinish}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 10 }}
                >

                    <Form.Item
                        label={'Email đăng nhập'}
                    >
                        {email}
                    </Form.Item>

                    <Form.Item
                        label={'Cài đặt mật khẩu'}
                        name="password"
                        rules={[
                            { required: true, message: 'Chưa nhập' }
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Mật khẩu"
                        />
                    </Form.Item>

                    <Form.Item
                        label={' '}
                        colon={false}
                    >
                        {
                            (registerMessage != "") && (
                                <div style={{color: 'red'}}>
                                    Lỗi xảy ra: {registerMessage}
                                </div>
                            )
                        }

                        <Button loading={registerStatus === 1} type="primary" htmlType="submit" className="login-form-button">
                            Cài đặt
                        </Button>
                    </Form.Item>

                </Form>

            </div>

        </>
    )
}


const Confirm = () => {

    const [setupStep, setStep]    = useState<string>('set-account');
    const location  = useLocation();
    const {email, tk: token} = queryString.parse(location.search);

    if(setupStep == 'set-account') {
        return (
            <>
                <SettingAccount
                    email={email ? email.toString() : ''}
                    token={token ? token.toString() : ''}
                    setStep={(step) => setStep(step)}
                />
            </>
        )
    }

    return (
        <SetPassword />
    )
}


export default Confirm;
