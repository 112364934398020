export default {
  'menu.welcome': 'Welcome',
  'menu.home': 'Home',
  'menu.admin': 'admin',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.system': 'System',
  'menu.system.template': 'Web Template',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Tài khoản cá nhân',
  'menu.account.settings': 'Thông tin tài khoản',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  // product
  'menu.product': 'Sản phẩm',
  'menu.product.product': 'Sản phẩm',
  'menu.product.product_list': 'Danh sách sản phẩm',
  'menu.product.category': 'Danh mục',
  'menu.product.collection': 'Bộ sưu tập',
  'menu.product.brand': 'Thương hiệu',
  'menu.product.attributes': 'Thuộc tính',
  'menu.shop': 'Cửa hàng',
  'menu.shop.shop' : 'Danh sách',
};
