import React  from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import {Router} from "@/routes";
import {ProvideAuth} from "@/components/Auth/login";

interface AppProps {
    history: History;
}

const App = ({ history }: AppProps) => {
    return (
        <ConnectedRouter history={history}>
            <ProvideAuth>
                <Router />
            </ProvideAuth>
        </ConnectedRouter>
    )
}

export default App;
