import React from "react";
import {DrawerComponentType} from "./typings";

export const componentMap: {[key in DrawerComponentType]?: {title: string, component: React.LazyExoticComponent<any>} | null} = {
    'support-detail' : {
        title: 'Chi tiết hỗ trợ',
        component: React.lazy(() => import('./components/SupportDetail')),
    },
    'contact-detail' : {
        title: 'Thông tin khách hàng',
        component: React.lazy(() => import('./components/ContactDetail')),
    },
}
