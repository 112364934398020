/*
 10-Jan-2023 utility to replace console.log
 we can disable all logs on browser in production by 1 line
*/

import {IS_DEV} from "@/config";

export default {
    log,
    error,
    info
}


function log(message?: any, ...optionalParams: any[]) {
    // disable on production
    if(!IS_DEV) return ;

    return console.log(message, optionalParams);
}

function error(message?: any, ...optionalParams: any[]) {
    // disable on production
    if(!IS_DEV) return ;

    return console.error(message, optionalParams);
}

function info(message?: any, ...optionalParams: any[]) {
    // disable on production
    if(!IS_DEV) return ;

    return console.info(message, optionalParams);
}